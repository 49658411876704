/* Kept CSS I've written down to minimum bu using Bootstrap framework, see https://getbootstrap.com/docs/5.2/getting-started/introduction/ */

/* Variables */
:root {
  --primary: #960150;
  --primaryText: rgb(43, 43, 43);
  --greyLight: #dee2e6;
  --greyDark: #999;
}

/* Global styles */
html {
  color: var(--primaryText);
}

/* Typography */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Permanent Marker', Arial, sans-serif;
  color: var(--primary);
  margin-bottom: 16px;
}

.subheader {
  display: block;
  font-family: var(--bs-body-font-family);
  text-transform: uppercase;
  font-size: 1rem;
  color: var(--greyDark);
}

a {
  color: var(--primary) !important;
}

a:hover {
  color: var(--primary) !important;
  opacity: .7;
}

/* Header and footer */
header::before,
/*footer::before */
#pseudo-footer::before

{
  content: '';
  width: 100%;
  height: 1000px;
  position: absolute;
  top: 0;
  background: url("../../public/images/explosive\ doodle.png") no-repeat center center fixed;
  background-size: cover; 
  opacity: .1;
}

h1 {
  position: relative;
  z-index: 999;
}

.close-btn {
  position: absolute;
  top: 8px;
  right: 12px;
}

/* Nav buttons */
.nav-btn {
  position: absolute;
  top: 0;
  width: 48px;
  left: 0;
  height: 100%;
  border: 0;
  border-right: 1px solid var(--greyLight);
  font-size: 2rem;
}

/* TODO: hide these buttons properly via the JS - or add a disabled propery for accessibility reasons */
.nav-btn[style="cursor: not-allowed;"] {
  display: none;
}

.nav-btn:hover {
  color: var(--primary);
}

.nav-btn__next {
  left: auto;
  right: 0;
  border-right: 0;
  border-left: 1px solid var(--greyLight);
}

/*home page button*/

.home .btn {
  border: 1px solid rgba(0,0,0,0.25);
}

/*landing page carousel image*/

.carousel-item img {
  width: 100% !important;
  height: auto !important;
}

/*Excess or reset button (this prevents buttons that wrap test images from changing colour when clicked*/

.excess-or-reset-button-container button {
  color: var(--primaryText) !important;   
  
}
/*
.excess-or-reset-button-container button:hover {
  background-color: var(--greyLight) !important; 
}
*/
.excess-or-reset-button-container button:active {
  background-color: var(--greyDark) !important;
}

.excess-or-reset-button-container button:disabled {
  color: var(--greyLight) !important;
}

.excess-or-reset-button-container button {
  color: var(--primary) !important;    
  font-size: 1rem;
  width: 100%;
}

/* text box */
.text-box {
  width: 75%;
  margin: 0 auto;
}

#intro-answer-sidenote {
  display: inline-flex;
  height: 40%;
}

#sidenote {
  padding-left: 5%;
  overflow-y: auto;
}

#text-box-image-container {
  width: 60%;
  margin: auto;
}

@media only screen and (max-width: 920px) {
  #text-box-image-container {
    width: 100%;
  }
}

@media only screen and (max-width: 740px) {
  .text-box {
      width: 90%;
  }  
}

.border-top {
  border-color: var(--primary) !important;
}

@media screen and (orientation: portrait){
  .carousel-item img {
    width: auto !important;
    height: 100% !important;
  }
}

@media screen and (min-width: 586px) and (max-width: 880px){
  .carousel-item img {
    width: auto !important;
    height: 100% !important;
  }

}


/*This was already here in the boiler plate*/
/*
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

*/