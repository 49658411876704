Variables 
:root {
    --primary: #960150;
    --primaryText: rgb(43, 43, 43);
    --greyLight: #dee2e6;
    --greyDark: #999;
  }

.examBoards button {
    color: var(--primaryText) !important;
    
    
  }

  .examBoards  button:hover {
    background-color: var(--greyLight) !important; 
  }
  
  .examBoards  button:active {
    background-color: var(--greyDark) !important;
  }

  .examBoards button:focus {
    background-color: #f8f9fa !important;
    /*color: white !important;*/
  }

  .examBoards button {
    color: var(--primary) !important;    
    
    width: 100%;
  }
  /*
  button:hover {
    color: var(--primaryText) !important;
    opacity: .7;
  }
background-color: var(--primary) !important;
  */