.reagent-bottle-selection {
    /*display: flex;
    flex-direction: column;*/
    padding: 2%;
    
}
/*
.reagent-bottle-image {
    max-width: 150px;
}
*/