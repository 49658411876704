.test-tube {
    position: relative;
    width: 100%;
    height: 50%;
    margin-top: 5%;
}

img {
    height: 100%;
    width: auto;
}

.absolute-positioning-experiment {
    position: absolute;
    left: 0;
}

.excess-button {
    position: relative;
}

@media only screen and (min-width: 720px) and (max-width: 995px) {
    .test-tube {
        height: 30%;
    }
}

@media only screen and (max-width: 720px) {
    .test-tube {
        height: 70%;
    }
}